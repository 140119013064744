import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import urls from 'backend_urls';
import { authHeaders, setCookie } from 'common';
import { toast } from 'react-toastify';


const initialState = {
    data: [],
    isLoading: false,
    error: null,
    apiError: null,
};

export const verify = createAsyncThunk('verify/otp', async ({ otp, phone }) => {
    try {
        const response = await axios.post(urls.verifyOTP, { otp: otp, phone: phone }, { headers: authHeaders() });
        toast.success(response.data.message);
        setCookie('token', response.data.data.token, 30);
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        return false;
    }
});

const VerifyOTPSlice = createSlice({
    name: 'verifyOtp',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(verify.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(verify.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(verify.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
});


export default VerifyOTPSlice.reducer;
