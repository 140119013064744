// material-ui
// import { Box, useMediaQuery } from '@mui/material';

// project import
import Search from './Search';
// import Profile from './Profile';
import Notification from './Notification';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
// import MobileSection from './MobileSection';
// import Logout from './Logout';
// import { getCookie } from 'common';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const location = useLocation();
  const { pathname } = location;

  const slugToName = (pathname) => {
    const matches = pathname.match(/\/([^/]+)\//) || pathname.match(/\/([^/]+)$/);

    if (matches && matches[1]) {
        // Replace '-' with ' ' and then capitalize each word.
        return matches[1].replace(/-/g, ' ').split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }
    return null;
  }
  // const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
		<>
			{pathname == '/home' ? (
				<Search />
			) : (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						{slugToName(pathname)}
					</Grid>
				</Grid>
				// <span style={{paddingLeft: 10}}>{slugToName(pathname)}</span>
			)}
			{/* {!matchesXs && <Search />} */}
			{/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

			<Notification />
			{/* {getCookie('token') && <Logout/>} */}
			{/* {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />} */}
		</>
  );
};

export default HeaderContent;
