// material-ui
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

import Google from 'assets/images/icons/google.svg';
import Apple from 'assets/images/icons/apple.png';
// import Facebook from 'assets/images/icons/facebook.svg';
import config from 'config';
import { whichAppOsIsThis } from 'common';
// import whichAppOsIsThis from 'common';

const FirebaseSocial = () => {
  const theme = useTheme();
  const device = whichAppOsIsThis();
  // const appOS = whichAppOsIsThis();
  // console.log("appOS",appOS);

  const googleHandler = async () => {
    window.location.href = `${config.backend_domain}/login/google/`
  };

  const appleHandler = async () => {
    window.location.href = `${config.backend_domain}/login/apple/`
  };

  // const facebookHandler = async () => { 
  //   window.location.href = `${config.backend_endpoint}login/facebook/`
  // };
  return (
		<>
			<Button
				variant="outlined"
				color="secondary"
				fullWidth={true}
				startIcon={<img src={Google} alt="Google" />}
				onClick={googleHandler}
				sx={{ width: '100%', borderRadius: 10, height: 45 }}
			>
				{'Google'}
			</Button>
			{device === 'ios' && (
				<Button
					variant="outlined"
					color="secondary"
					fullWidth={true}
					startIcon={<img src={Apple} alt="Apple" />}
					onClick={appleHandler}
					sx={{ width: '100%', borderRadius: 10, height: 45 }}
				>
					{'Apple'}
				</Button>
			)}
		</>
  );
};

export default FirebaseSocial;
