import { Language } from 'language';
// assets
import {
  UserOutlined,
  HistoryOutlined,
  CarOutlined,
  IdcardOutlined,
  BarChartOutlined,
  CalendarOutlined,
  QuestionOutlined,
  HomeOutlined,
  WalletOutlined,
  LogoutOutlined,
  ContactsOutlined,
  BorderOuterOutlined,
  CustomerServiceOutlined,
  HeartOutlined,
  BellOutlined
} from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  HistoryOutlined,
  CarOutlined,
  IdcardOutlined,
  BarChartOutlined,
  CalendarOutlined,
  QuestionOutlined,
  HomeOutlined,
  WalletOutlined,
  LogoutOutlined,
  ContactsOutlined,
  BorderOuterOutlined,
  HeartOutlined,
  CustomerServiceOutlined,
  BellOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const myAccount = {
  id: 'group-menu',
  title: '',
  type: 'group',
  children: [
    // {
    //   id: 'home',
    //   title: 'Home',
    //   type: 'item',
    //   url: '/home',
    //   icon: icons.HomeOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'myaccount',
      title: Language.heading.profile,
      type: 'item',
      url: '/my-account',
      icon: icons.UserOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.profile_url_desc,
    },
    {
      id: 'notifications',
      title: Language.heading.notification,
      type: 'item',
      url: '/notifications',
      icon: icons.BellOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.notification_url_desc,
    },
    {
      id: 'chargingHistory',
      title: Language.heading.charging_history,
      type: 'item',
      url: '/charging-history',
      icon: icons.HistoryOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.charging_history_url_desc,
    },
    {
      id: 'wallet',
      title: Language.heading.wallet,
      type: 'item',
      url: '/wallet',
      icon: icons.WalletOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.wallet_url_desc,
    },
    // {
    //   id: 'myVehicles',
    //   title: Language.heading.my_vehicles,
    //   type: 'item',
    //   url: '/my-vehicles',
    //   icon: icons.CarOutlined,
    //   breadcrumbs: true,
    //   url_desc: Language.heading.my_vehicles_url_desc,
    // },
    {
      id: 'favourite',
      title: Language.heading.favourite_charging_station,
      type: 'item',
      url: '/favourite-chargers/',
      icon: icons.HeartOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.favourite_charging_station_url_desc,
    },
    {
      id: 'rfid',
      title: Language.heading.rfid_registrations,
      type: 'item',
      url: '/rfid',
      icon: icons.IdcardOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.rfid_registrations_url_desc,
    },
    {
      id: 'privacyPolicy',
      title: Language.heading.privacy_policy,
      type: 'item',
      url: '/privacy-policy',
      icon: icons.BorderOuterOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.privacy_policy_us_url_desc,
    },
    {
      id: 'customerSupport',
      title: Language.heading.customer_support,
      type: 'item',
      url: '/faq',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.customer_support_url_desc,
    },
    {
      id: 'contactUs',
      title: Language.heading.contact_us,
      type: 'item',
      url: '/contact-us',
      icon: icons.ContactsOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.contact_us_url_desc,
    },
    {
      id: 'logout',
      title: Language.heading.logout,
      type: 'item-login',
      url: '/logout',
      icon: icons.LogoutOutlined,
      breadcrumbs: true,
      url_desc: Language.heading.logout_url_desc,
    }

    // {
    //   id: 'MyChargingCards',
    //   title: 'My Charging Cards',
    //   type: 'item',
    //   url: '/my-charging-cards',
    //   icon: icons.IdcardOutlined,
    //   breadcrumbs: true
    // },
    // {
    //   id: 'myUsage',
    //   title: 'My Usage',
    //   type: 'item',
    //   url: '/my-usage',
    //   icon: icons.BarChartOutlined,
    //   breadcrumbs: true
    // },

    // {
    //   id: 'myBookings',
    //   title: 'My Bookings',
    //   type: 'item',
    //   url: '/my-bookings',
    //   icon: icons.CalendarOutlined,
    //   breadcrumbs: true
    // },
    // {
    //   id: 'helpSupport',
    //   title: 'Help & Support',
    //   type: 'item',
    //   url: '/help-support',
    //   icon: icons.QuestionOutlined,
    //   breadcrumbs: true
    // },

  ]
};

export default myAccount;
