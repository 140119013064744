// material-ui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
// import Skeleton from '@mui/material/Skeleton';
// import Stack from '@mui/material/Stack';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

// ==============================|| Loader ||============================== //

const Loader = () => (
	<LoaderWrapper>
		<div style={{ position: 'fixed', background: 'black', opacity: 0.2, height: '100%', width: '100%', zIndex: 999999 }}></div>
		<CircularProgress sx={{position: "fixed",top: "45%", left: "45%", height: 10, width: '100%' }} color="primary" />
    {/* <Stack spacing={1} sx={{position: "fixed",top: 100, left: 50, }}>
      
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack> */}
	</LoaderWrapper>
);

export default Loader;
