import { useState, useEffect, useRef } from 'react';
import { Box, FormControl, InputAdornment, Autocomplete, TextField, Typography, Grid } from '@mui/material';

import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import urls from 'backend_urls';
import { dispatchCustomEvent } from 'EventDispatcher';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Search = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState('');
	const [placeSearchResult, setPlaceSearchResult] = useState([]);
	const [optionSelected, setOptionSelected] = useState(false);
	const blurRef = useRef(null);
	const inputRef = useRef(null);

	const handleDispatchEvent = (geometry) => {
		const eventData = { geometry: geometry };
		setOptionSelected(true);
		dispatchCustomEvent('selectPlaceAutoComplete', eventData);
		if (blurRef.current) {
			blurRef.current.focus();
		}
	};

	const handleSearch = (event, value) => {
		setSearchText(value);
		if (value.trim() !== '' && value.length > 2) {
			navigate('/home');
			const url = `${urls.placeSearch}${encodeURIComponent(value)}/`;
			axios
				.get(url)
				.then((response) => {
					let result = [];
					response.data.data.results.forEach((item) => {
						const label = item.name;
						const formatted_address = item.formatted_address;
						const geometry = item.geometry.location;
						const rating = item.rating;
						const icon = item.icon;
						result.push({
							label: label,
							formatted_address: formatted_address,
							geometry: geometry,
							rating: rating,
							icon: icon
						});
					});
					setPlaceSearchResult(result);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			setPlaceSearchResult([]);
		}
	};

	useEffect(() => {
		// Reset the optionSelected state after a short delay
		if (optionSelected) {
			const timeout = setTimeout(() => {
				setOptionSelected(false);
			}, 100);

			// Cleanup the timeout when the component unmounts or optionSelected changes.
			return () => clearTimeout(timeout);
		}
	}, [optionSelected]);

	return (
		<Box sx={{ width: '100%', ml: { xs: 1, md: 1 } }}>
			<FormControl sx={{ width: { xs: '100%', md: 224 }, minWidth: '100%' }}>
				<Autocomplete
					ref={inputRef}
					freeSolo
					id="header-search"
					options={placeSearchResult}
					value={searchText}
					onInputChange={handleSearch}
					renderOption={(props, option) => (
						<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, maxHeight: '100%', height: 'auto' }} {...props}>
							<img loading="lazy" width="20" src={option.icon} alt="" />
							<Grid
								container
								//  sx={{ borderBottom: '1px solid rgba(128, 128, 128, 0.1)' }}
							>
								<Grid item xs={12}>
									<Typography sx={{ fontSize: '12px' }}>{option.label}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography sx={{ fontSize: '10px' }}>{option.formatted_address}</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							size="small"
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start" sx={{ mr: -0.5, color: theme.palette.text.primary }}>
										<SearchOutlined />
									</InputAdornment>
								),
								'aria-label': 'weight'
							}}
							placeholder="Search......"
						/>
					)}
					onChange={(event, option) => {
						if (option !== null && option !== undefined) {
							handleDispatchEvent(option.geometry);
							event.persist();
							setTimeout(() => {
								event.target.blur();
							}, 0);
						}
					}}
				/>
				{/* This div will be used to remove focus from the input field */}
				<div tabIndex={-1} ref={blurRef} style={{ width: 0, height: 0, overflow: 'hidden' }} />
			</FormControl>
		</Box>
	);
};

export default Search;
