import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import urls from 'backend_urls';
import { toast } from 'react-toastify';

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    apiError: null,
};

export const activeCountry = createAsyncThunk('active/country', async () => {
    try {
        const response = await axios.get(urls.getActiveCountries);
        return response.data;
    } catch (error) {
        console.error(error.response.data.message);
        return false;
    }
});

const ActiveCountrySlice = createSlice({
    name: 'activeCountry',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(activeCountry.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(activeCountry.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(activeCountry.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
});


export default ActiveCountrySlice.reducer;