import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import urls from 'backend_urls';
import { authHeaders } from 'common';
import { toast } from 'react-toastify';

const initialState = {
    data: [],
    isLoading: false,
    error: null,
    apiError: null,
    phone: false,
    code: false,
};

export const loginOtp = createAsyncThunk('login/otp', async ({ phone, code }) => {
    try {
        const response = await axios.post(urls.sendLoginOTP, { phone: phone, dial_code: code }, { headers: authHeaders() });
        toast.success(response.data.message);
        return ({ response: response.data, phone, code });
    } catch (error) {
        toast.error(error.response.data.message);
        return { response: false, phone: false };
    }
});

const OTPSlice = createSlice({
    name: 'otp',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginOtp.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(loginOtp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
                state.phone = action.payload.phone;
                state.code = action.payload.code;
            })
            .addCase(loginOtp.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
});


export default OTPSlice.reducer;