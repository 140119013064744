import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { dispatchCustomEvent } from 'EventDispatcher';
import Loader from 'components/Loader';
import axios from 'axios';
import urls from 'backend_urls';
import { authHeaders } from 'common';
import { useSelector } from 'react-redux';

const CountryCodes = () => {
	const blurRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const handleDispatchEvent = (flag, country, code) => {
		const eventData = { flag: flag, country: country, code: code };
		dispatchCustomEvent('selectCountryCode', eventData);
		if (blurRef.current) {
			blurRef.current.focus();
		}
		// props.setOpenDrawer(false);
	};
	const activeCountries = useSelector(state => state.activeCountry.data)

	return (
		<>
			{isLoading && <Loader />}
			<Grid container>
				{activeCountries.map((country, country_key) => (
					<Grid
						item
						key={country_key}
						xs={12}
						onClick={() => {
							handleDispatchEvent(
								`https://flagcdn.com/w20/${country.short_code.toLowerCase()}.png`,
								`${country.short_code}`,
								`+${country.dial_code}`
							);
						}}
					>
						<Grid container>
							<Grid item xs={1}>
								<img src={`https://flagcdn.com/w20/${country.short_code.toLowerCase()}.png`} alt="IN"></img>
							</Grid>
							<Grid item xs={9}>
								{country.name}
							</Grid>
							<Grid item xs={2}>
								+{country.dial_code}
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default CountryCodes;