import langEn from './language.en';
import langTh from './language.th';
import langId from './language.id';

let selected = localStorage.getItem('selectedLanguage') || 'en';

let LangSet = { en: langEn.en, th: langTh.th, id: langId.id };

let Language = LangSet['en'];
Language = LangSet[selected];

const LangKeyValues = [
	{
		name: 'English',
		localeName: 'English',
		countryCode: ['in'],
		code: 'en'
	},
	// {
	//   name: "Hindi",
	//   localeName: "हिन्दी",
	//   countryCode: ['in'],
	//   code: 'en',
	// },
	// {
	//   name: "Bengali",
	//   localeName: "বাংলা",
	//   countryCode: ['in'],
	//   code: 'en',
	// },
	// {
	//   name: "Japanese",
	//   localeName: "日本語",
	//   countryCode: ['jp'],
	//   code: 'en',
	// },
	{
		name: 'Thai',
		localeName: 'ไทย',
		countryCode: ['th'],
		code: 'th'
	},
	{
		name: 'Bahasa',
		localeName: 'Bahasa',
		countryCode: ['id'],
		code: 'id'
	}
	// {
	//   name: "Spanish",
	//   localeName: "Español",
	//   countryCode: ['es'],
	//   code: 'en',
	// }
];

const getCurrentLanguage = () => {
	return selected;
};

const getLanguageByCountry = (countryCode) => {
	const filtered = LangKeyValues.filter((l) => l.countryCode.includes(countryCode.toLowerCase()));

	return filtered.length > 0 ? LangSet[filtered[0].code] : LangSet['en'];
};

export { Language, LangKeyValues, getCurrentLanguage, getLanguageByCountry };
