import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const requestChargerSlice = createSlice({
    name: 'requestCharger',
    initialState: {
        data: null
    },
    reducers: {
        openRequestChargerModel: (state, action) => {
            state.data = true;
        },
        closeRequestChargerModel: (state, action) => {
            state.data = false;
        }
    }
})

export const { openRequestChargerModel, closeRequestChargerModel } = requestChargerSlice.actions;
export default requestChargerSlice.reducer