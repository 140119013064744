import { createSlice } from '@reduxjs/toolkit';


const selectedChargeValueSlice = createSlice({
    name: "selectedValue",
    initialState: {
        tab: false,
        value: false,
        chargerId : false,
        estimatePrice: 0
    },
    reducers:{
        setSelectedValue: (state,action) =>{
            return action.payload;
        },
        clearSelectedValue: (state,action) =>{
            return {
                tab: false,
                value: false,
                chargerId : false,
                estimatePrice: 0
            }
        }
    }
})

export const {setSelectedValue, clearSelectedValue} = selectedChargeValueSlice.actions;
export default selectedChargeValueSlice.reducer;