import PropTypes from 'prop-types';
import { useMemo, useEffect, useState } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';

import { getFromLocalStorage } from 'common';
import { useSelector } from 'react-redux';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
	const [theme, setTheme] = useState(Palette(getFromLocalStorage('theme') || 'light'));
	const isLoggedIn = useSelector(state => state.login.data)
	const themeTypography = Typography(`'Public Sans', sans-serif`);
	const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

	const themeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1536
				}
			},
			direction: 'ltr',
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8
				}
			},
			palette: theme.palette,
			customShadows: themeCustomShadows,
			typography: themeTypography
		}),
		[theme, themeTypography, themeCustomShadows]
	);

	const themes = createTheme(themeOptions);
	themes.components = componentsOverride(themes);
	useEffect(() => {
		const handleChangeTheme = (event) => {
			setTheme(Palette(event.detail));
		};

		document.addEventListener('changeTheme', handleChangeTheme);

		return () => {
			document.removeEventListener('changeTheme', handleChangeTheme);
		};
	}, [isLoggedIn]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

ThemeCustomization.propTypes = {
	children: PropTypes.node.isRequired
};
