import { Language } from 'language';
import React, { useState } from 'react';

function ErrorHandlerWrapper(props) {
  const [hasError, setHasError] = useState(false);
  const [errorInfo, setErrorInfo] = useState(null);

  return {
    ...props.children, // spread children properties

    getDerivedStateFromError(error) {
      setHasError(true);
      setErrorInfo(error.toString());
      return null;
    },

    componentDidCatch(error, info) {
      console.error(Language.message.error_boundary, error, info);
    },

    render() {
      if (hasError) {
        return React.createElement('div', null, 
          React.createElement('h1', null, Language.message.something_went_wrong),
          React.createElement('details', null, errorInfo)
        );
      }
      return props.children;
    }
  };
}

export default ErrorHandlerWrapper;
