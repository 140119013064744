import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const appliedCouponSlice = createSlice({
    name: 'appliedCoupon',
    initialState: {
        data: null
    },
    reducers: {
        setCoupon: (state,action)=>{
            state.data = action.payload;
        },
        removeCoupon: (state,action)=>{
            state.data =null;
        }
    }
})

export const {setCoupon, removeCoupon} = appliedCouponSlice.actions;
export default appliedCouponSlice.reducer