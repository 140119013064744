import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import urls from 'backend_urls';
import { authHeaders, isLoggedIn } from 'common';
import { toast } from 'react-toastify';


const initialState = {
    data: { "name": "Guest" },
    isLoading: false,
    error: null,
    apiError: null,
};

export const User = createAsyncThunk('user/fetchLoginDetails', async () => {
    let token = isLoggedIn();
    if (token) {
        try {
            const response = await axios.get(urls.user, { headers: authHeaders() });
            return response.data.data;
        } catch (error) {
            localStorage.removeItem("token");
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
            toast.error(error.response.data.message);
            return false;
        }
    } else {
        return initialState.data
    }
});

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(User.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(User.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(User.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
});


export default UserSlice.reducer;
