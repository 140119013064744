import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import urls from 'backend_urls';
import { authHeaders, setCookie } from 'common';
import { toast } from 'react-toastify';

const initialState = {
    data: [],
    isLoading: false,
    error: null,
    apiError: null,
};

export const loginUser = createAsyncThunk('login/fetchLoginDetails', async ({ phone, code, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(urls.login, { phone: phone, country_code: code, password: password }, { headers: authHeaders() });
        toast.success(response.data.message);
        setCookie('token', response.data.data.token, 30);
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        return false;
    }
});

const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        clearLoginState: (state) => {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
});


export const { clearLoginState } = LoginSlice.actions;
export default LoginSlice.reducer;
