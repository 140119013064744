import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Grid, Typography, TextField, Button, Box } from '@mui/material';
import FirebaseSocial from 'pages/authentication/auth-forms/FirebaseSocial';
import { Language } from 'language';
import SwipeableEdgeDrawer from 'components/Drawer';
import CountryCodes from 'components/CountryCodes';
import Logo from 'components/icons';
import axios from 'axios';
import urls from 'backend_urls';
import { toast } from 'react-toastify';
import { setCookie, authHeaders, getGeoCountryCode, getFromLocalStorage, deleteFromLocalStorage } from 'common';
import Loader from 'components/Loader';
import { useDispatch } from 'react-redux';
import { loginUser } from 'store/reducers/loginSlice';
import { loginOtp } from 'store/reducers/otpSlice';
import { useSelector } from 'react-redux';

const Phone = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [flag, setFlag] = useState('https://flagcdn.com/w20/in.png');
	const [code, setCode] = useState('+91');
	const [country, setCountry] = useState('IN');
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState(false);
	const [isPhonePasswordValid, setIsPhonePasswordValid] = useState(false);
	const [isPhoneValid, setIsPhoneValid] = useState(false);
	const countries = useSelector(state => state.activeCountry.data)
	const [otpOrPasswordStatus, setOtpOrPasswordStatus] = useState(true);

	const handleCountryCode = () => {
		if (!openDrawer) setOpenDrawer(true);
		else setOpenDrawer(false);
	};
	const handleSelectCountryCodeEvent = (event) => {
		setCountry(event.detail.country);
		setOpenDrawer(false);
	};
	const handlePhoneInput = (event) => {
		const arrayConstructor = [].constructor;
		let phoneNumber = event.target.value;
		phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
		phoneNumber = phoneNumber.slice(0, parseInt(selectedCountry.phone_length));
		setPhone(phoneNumber);
		if (selectedCountry.phone_conditions?.constructor !== arrayConstructor) {
			setIsPhoneValid(true);
			if (isPasswordValid) setIsPhonePasswordValid(true);
		} else {
			const isValid = selectedCountry.phone_conditions.every((cond) => {
				const regex = new RegExp(cond);
				return regex.test(phoneNumber);
			});
			setIsPhoneValid(isValid);
			if (isPasswordValid && isValid) setIsPhonePasswordValid(true);
			else setIsPhonePasswordValid(false);
		}
	};
	const handlePasswordInput = (event) => {
		let password = event.target.value.trim();
		setPassword(password);
		if (password.length >= 4) {
			setIsPasswordValid(true);
			if (isPhoneValid) setIsPhonePasswordValid(true);
		} else {
			setIsPasswordValid(false);
			setIsPhonePasswordValid(false);
		}
	};
	const validatePhoneNumber = async () => {
		try {
			await dispatch(loginOtp({ phone, code }));
			navigate('/onboarding/otp');
		} catch (error) {
			toast.error(error.response.data.message);
		}
	};

	const validatePhoneNumberAndPassword = async () => {
		if (isPhonePasswordValid) {
			dispatch(loginUser({ phone, code, password }))
			let login_next = getFromLocalStorage('login_next');
			if (login_next) navigate(login_next);
			else navigate('/home');
			deleteFromLocalStorage('login_next');
		}
	};


	useEffect(() => {
		document.addEventListener('selectCountryCode', handleSelectCountryCodeEvent);
		return () => {
			document.removeEventListener('selectCountryCode', handleSelectCountryCodeEvent);
		};
	}, [openDrawer]);

	useEffect(() => {
		if (selectedCountry) {
			handlePhoneInput({
				target: {
					value: phone
				}
			});
		}
	}, [selectedCountry]);

	useEffect(() => {
		if (countries) {
			const found = countries.find((c) => c.short_code === country);
			setFlag(`https://flagcdn.com/w20/${country.toLowerCase()}.png`);
			setCode('+' + found?.dial_code ?? '91');
			setSelectedCountry(found);
		}
	}, [countries, country]);

	useEffect(() => {
		(async () => {
			const countryCode = (await getGeoCountryCode()) || 'IN';
			setCountry(countryCode.toUpperCase());
		})();
	}, []);

	return (
		<Box style={{ height: "100vh" }}>
			{isLoading && <Loader />}
			<Grid container padding={2}>
				{openDrawer && (
					<SwipeableEdgeDrawer
						header={Language.placeholder.country_code}
						setOpenDrawer={setOpenDrawer}
						component={<CountryCodes />}
					/>
				)}
				<Grid item xs={12}>
					<Typography variant="h4">{Language.label.enter_mobile_number}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption" sx={{ color: 'grey' }}>
						{Language.label.confirm_country_code_enter_mobile_number}
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
					display="flex"
					justifyContent="center"
					alignItems="flex-end"
					sx={{ borderBottom: '1px solid rgb(0,0,0,0.5)', pb: 1 }}
				>
					{flag && <img src={flag} alt={country}></img>}
				</Grid>
				<Grid item xs={1.7}>
					<TextField
						sx={{ width: '100%' }}
						label={Language.placeholder.country_code}
						variant="standard"
						value={code}
						onClick={handleCountryCode}
					// onFocus={handleCountryCode}
					/>
				</Grid>
				<Grid item xs={0.5}></Grid>
				<Grid item xs={8.8}>
					<TextField
						sx={{ width: '100%' }}
						value={phone}
						onChange={handlePhoneInput}
						label={Language.placeholder.mobile_number}
						variant="standard"
					/>
				</Grid>

				<Grid item xs={12} pb={1} pt={1}>
					{otpOrPasswordStatus ? (
						<></>
					) : (
						<TextField
							sx={{ width: '100%' }}
							value={password}
							onChange={handlePasswordInput}
							label={Language.button.password}
							variant="standard"
						/>
					)}
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
					{otpOrPasswordStatus ? (
						<>
							<Button
								onClick={validatePhoneNumber}
								variant="contained"
								sx={{ width: '100%', borderRadius: 10, height: 45 }}
								disabled={!isPhoneValid}
							>
								{Language.button.continue}
							</Button>
						</>
					) : (
						<>
							<Button
								onClick={validatePhoneNumberAndPassword}
								variant="contained"
								sx={{ width: '100%', borderRadius: 10, height: 45 }}
								disabled={!isPhonePasswordValid}
							>
								{Language.heading.login}
							</Button>
						</>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					mt={4.5}
					display="flex"
					justifyContent="center"
					alignItems="center"
					sx={{ borderTop: '1px solid rgb(0,0,0,0.1)' }}
				>
					<Typography sx={{ position: 'relative', pl: 2, pr: 2, top: -22, fontSize: 25, backgroundColor: 'rgb(250,250,251)' }}>
						{Language.generic.or.toUpperCase()}
					</Typography>
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
					<FirebaseSocial />
				</Grid>
			</Grid>
			<Grid container sx={{ position: 'fixed', bottom: 0 }}>
				<Grid item xs={12} pb={2} display="flex" justifyContent="center" alignItems="center">
					<Typography sx={{ fontSize: 10 }}>
						{Language.generic.agree_terms_conditions} {Language.generic.terms_and_conditions} {Language.generic.and}{' '}
						{Language.generic.privacy_policy}
					</Typography>
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
					<Typography sx={{}}>{Logo()}</Typography>
				</Grid>
			</Grid>
			<Box style={{ position: "absolute", bottom: "100px", width: "100%" }}>
				<Grid item xs={12} pt={0.5} pb={0.5} display="flex" justifyContent="center" alignItems="center" >
					<Button
						sx={{ fontSize: 16, textDecoration: "underline" }}
						variant="text"
						onClick={() => {
							setOtpOrPasswordStatus(!otpOrPasswordStatus);
						}}
					>
						{otpOrPasswordStatus ? Language.label.password_login : Language.label.otp_login}
					</Button>
				</Grid>
			</Box>
		</Box>
	);
};
export default Phone;
