import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
// import MainRoutes from './MainRoutes';
import MyAccountRoutes from './MyAccountRoutes';
import MobileRoutes from './MobileRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MyAccountRoutes, LoginRoutes, MobileRoutes]);
  // return useRoutes([MyAccountRoutes, MainRoutes, LoginRoutes]);
  // return useRoutes([MyAccountRoutes]);
}
