const Language = {
	id: {
		label: {
			view_profile: 'Lihat Profil',
			list: 'Daftar Stasiun',
			enter_mobile_number: 'Masukkan nomor ponsel Anda 📱',
			enter_your_name: 'Hai, siapa namamu? 👋',
			enter_your_email: 'Masukkan alamat email Anda 📧',
			email_message: 'Alamat email diperlukan untuk pembuatan faktur',
			confirm_country_code_enter_mobile_number: 'Harap konfirmasi kode negara dan nomor ponsel Anda.',
			mobile_verification: 'Verifikasi ponsel',
			confirm_mobile_verification_code: 'Masukkan 4 digit kode yang dikirimkan ke ponsel Anda untuk verifikasi.',
			connectors: 'Daftar Konektor:',
			connector_type: 'Tipe Konektor',
			units_consumed: 'Unit Terkonsumsi',
			amount_spent: 'Jumlah Terpakai',
			co2_saved: 'Pengurangan CO2',
			name: 'Nama',
			mobile: 'Ponsel',
			email: 'Email',
			vehicle_number: "Nomor Kendaraan",
			battery_started_at: 'Baterai Dimulai Pada',
			remperreq: 'Rem./Req.',
			time: 'Waktu',
			amt: 'Jml.',
			elapsed_time: 'Waktu Pemakaian',
			remaining_time: 'Waktu Tersisa',
			remaining_amount: 'Jumlah Tersisa',
			remaining_unit: 'Sisa Unit',
			charging_summary: 'Ringkasan Pengisian Daya',
			charger_id: 'ID Pengisi Daya',
			start_time: 'Waktu Mulai',
			end_time: 'Waktu Selesai',
			charge_start_percent: '% Mulai Pengisian',
			charge_end_percent: '% Selesai Pengisian',
			gun: 'Konektor',
			pricing: 'Harga',
			end_reason: 'Sebab Pemutusan',
			charging_status: 'Status Pengisian Daya',
			connected: 'Terhubung',
			charging: 'Sedang Mengisi',
			battery_information: 'Informasi Pengisian Daya',
			consumed: 'Terkonsumsi',
			battery: 'Baterai',
			amount: 'Jumlah',
			total: 'Total',
			free: 'Gratis',
			private: 'Privat',
			public: 'Publik',
			capacity: 'Kapasitas',
			price: 'Harga',
			unit: 'Unit',
			set_time: 'Set Waktu',
			set_unit: 'Set Unit',
			set_price: 'Set Harga',
			wallet_balance: 'Saldo Dompet',
			wallet_balance_kwh: 'Saldo kWh',
			available_balance: 'Saldo Tersedia',
			estimate: 'Estimasi',
			estimate_kwh: 'Perkiraan kWh',
			enter_valid_phone_number: 'Masukkan Nomor Telopon yang Valid',
			enter_valid_otp: 'Masukkan OTP yang Valid',
			phone_number: 'Nomor Telepon',
			country: 'Negara',
			enter_otp: 'Masukkan OTP',
			firstname: 'Nama Depan',
			email_address: 'Alamat Email',
			password: 'Kata Sandi',
			otp: 'OTP',
			keep_me_sign_in: 'Buat saya tetap login',
			india: 'India',
			japan: 'Japan',
			thailand: 'Thailand',
			settings: 'Pengaturan',
			change_language: 'Ganti Bahasa',
			select_language: 'Pilih bahasa',
			full_charge: 'Terisi Penuh',
			indonesia: 'Indonesia',
			charger_status: 'Status Pengisi Daya',
			faulted: 'Gangguan',
			finishing: 'Menyelesaikan',
			charging_fee: 'Biaya Pengisian Daya',
			booking_overtime: 'Reservasi Lewat Waktu',
			overtime_fee: 'Biaya Lewat Waktu',
			your_booking_overtime: 'Reservasi anda lewat waktu',
			invoice_address: 'Alamat',
			otp_login: 'Klik ini: untuk login dengan OTP',
			password_login: 'Klik ini: untuk login dengan kata sandi',
			initial_balance: "saldo awal",
			final_balance: "saldo akhir",
			order_placed: "Pesanan Anda telah dikonfirmasi dan akan segera dikirim",
			order_id: "ID Pesanan",
			order_date: "Tanggal Pesanan",
			estimate_delivery: "Perkiraan Pengiriman",
			delivery_status: "Status Pengiriman",
			tracking_id: "ID Pelacakan",
			rfid: "RFID",
			terra_charge: "Terra Charge",
			grow_together: "Mari tumbuh bersama",
			order_rfid: "Pesan untuk RFID",
			claim_rfid: "Klaim Kartu RFID Gratis Anda",
			coupon:  "Kode",
			coupon_discount: "Diskon Kupon",
			add_a_review: "Tambahkan Ulasan",
			payment: "pembayaran",
			billing: "penagihan",
			vehicle: "Kendaraan",
			disconnected: "Terputus",
			lcoation_type: "Jenis Lokasi",
			amount_deducted: "Jumlah Dikurangi"
		},
		placeholder: {
			mobile_number: 'Nomor Ponsel',
			enter_full_name: 'Masukkan nama lengkap',
			enter_email: 'Masukkan alamat email',
			country_code: 'Kode Negara',
			otp: 'Kata sandi OTP',
			resend_otp: 'Kirim lagi dalam {seconds} detik',
			phone_number: 'Masukkan nomor telepon yang valid',
			enter_password: 'Masukkan kata sandi',
			enter_otp: 'Masukkan OTP',
			firstname: 'John Doe',
			email_address: 'demo@company.com',
			faq_whatsapp: "Ketik pertanyaan Anda",
			payment: "การชำระเงิน",
			billing: "การเรียกเก็บเงิน"
		},
		heading: {
			amount_in_wallet: 'Saldo di dompet Anda:',
			balance: 'Saldo',
			balance_kwh: 'kWh Saldo',
			last_transaction: 'Transaksi Terakhir',
			terms_of_service: 'Ketentuan Layanan',
			privacy_policy: 'Kebijakan Privasi',
			please_type_the: 'Harap masukkan',
			add_vehicle: 'Tambah Kendaraan',
			my_usage: 'Pemakaian Saya',
			charging_sessions: 'Sesi Pengisian Daya',
			upcoming: 'Mendatang',
			past: 'Lampau',
			cancelled: 'Dibatalkan',
			facing_issue_charging: 'Ada masalah saat mengisi daya?',
			terra_wallet: 'Dompet Terra',
			estmate_approx: 'Estimasi (Perkiraan)',
			already_have_account: 'Sudah punya akun?',
			signup: 'Daftar',
			dont_have_account: 'Belum punya akun?',
			login: 'Login',
			forgot_password: 'Lupa Kata Sandi',
			back_to_login: 'Kembali ke login',
			home: 'Depan',
			signup_with: 'Daftar dengan',
			login_with: 'Login dengan',
			dashboard: 'Dasbor',
			profile: 'Profil',
			wallet: 'Dompet',
			my_vehicles: 'Kendaraan Saya',
			charging_history: 'Riwayat Pengisian Daya',
			contact_us: 'Hubungi Kami',
			logout: 'Logout',
			map: 'Peta',
			history: 'Riwayat',
			terra_motors_country: 'Terra Motors Indonesia.',
			available_coupons: 'Kupon yang Tersedia',
			rate_session: "Apakah Anda ingin menilai sesi ini?",
			profile_url_desc: 'Nama, nomor telepon, tambah dan edit alamat',
			wallet_url_desc: 'Tambah saldo dompet, transaksi mudah, lihat detail transaksi',
			my_vehicles_url_desc: 'Kendaraan Saya',
			charging_history_url_desc: 'Lihat riwayat pengisian daya dengan detail yang lengkap',
			contact_us_url_desc: 'Mengalami masalah? Dapatkan jawaban dan dukungan dari kami',
			logout_url_desc: 'Keluar dari profil Anda',
			map_url_desc: 'Peta',
			history_url_desc: 'Riwayat',
			privacy_policy_us_url_desc: "Baca bagaimana data dikumpulkan, digunakan, dan dilindungi",
			rfid_registrations_url_desc: 'Daftar, dapatkan kartu RFID, dan ketuk pemindai untuk mengisi daya',
			favourite_charging_station: 'Stasiun pengisian daya favorit',
			rfid_registrations: 'Registrasi RFID',
			favourite_charging_station_url_desc: "Lihat stasiun pengisian daya yang telah Anda tambahkan sebagai favorit",
			customer_support: "Dukungan Pelanggan",
			customer_support_url_desc: "Dapatkan jawaban atas pertanyaan Anda",
			faq: "PERTANYAAN YANG SERING DITANYAKAN",
			notification: 'Pemberitahuan',
			notification_url_desc: 'Lihat detail pengisian daya sebelumnya dengan kendaraan, dompet, dan penagihan Anda',
			ratings: 'Peringkat',
			last_session: 'Sesi Terakhir',
			total_connectors: 'Senjata',
			rating: 'Peringkat',
			available_connectors: 'Konektor yang Tersedia'
		},
		list: {
			charging_statuses: {
				charging: 'Mengisi',
				abandoned: 'Ditinggalkan',
				available: 'Tersedia',
				completed: 'Selesai',
				initiated: 'Dimulai',
				preparing: 'Menyiapkan'
			}
		},
		button: {
			continue: 'lanjutkan',
			save: 'Simpan',
			topup: 'Top Up',
			add_money: 'Tambah Dana',
			map_view: 'Tampilan Peta',
			type_device_id: 'Tipe ID Perangkat',
			activate: 'Aktifkan',
			resend_otp: 'Kirim Ulang OTP',
			password: 'Kata Sandi',
			cancel: 'Batal',
			update: 'Perbarui',
			logout: 'Logout',
			cancel_goback: 'Batal, Kembali',
			confirm_delete: 'Yakin Hapus',
			continue_on_app: 'Saya yakin, ingin melanjutkan dengan aplikasi Web.',
			click_to_call: 'Klik ini untuk menghubungi kami',
			or_dial: ' atau telepon',
			stop_charging: 'Hentikan Pengisian',
			wallet_link: 'Dompet',
			next_disabled: 'Tombol akan aktif, setelah waktu atau biaya dipilih.',
			next: 'Berikutnya',
			start_charging: 'Memulai Pengisian',
			verify_otp: 'Verifikasi OTP',
			send_otp: 'Kirim OTP',
			send_password: 'Kirim Kata Sandi',
			create_account: 'Buat Akun',
			login: 'Login',
			credit: 'Kredit/Debit',
			qr: 'QR',
			select: 'Pilih',
			confirm_country_change: 'Konfirmasi',
			scan_to_checkout: 'Pindai untuk Checkout',
			make_payment: 'Bayar',
			download_invoice: 'Unduh tagihan',
			generate_invoice: 'Hasilkan Faktur',
			purchase_kwh: "pembelian kWh",
			apply_now: "Lamar Sekarang",
			back_to_home: "Kembali ke beranda",
			track_shipment: "Lacak pengiriman",
			place_order: "Buat Pesanan",
			already_requested: "Sudah Diminta",
			apply_coupon: "Terapkan Kupon",
			submit: "Kirim",
			apply_all: "Terapkan Semua",
			clear_all: "Hapus Semua"
		},
		generic: {
			or: 'atau',
			and: 'dan',
			continue_with_google: 'Lanjutkan dengan Google',
			agree_terms_conditions: 'Bila melanjutkan, Anda menyetujui',
			terms_and_conditions: 'Syarat & ketentuan',
			privacy_policy: 'Kebijakan Privasi',
			currency_char: 'Rp',
			device_id: 'ID Perangkat',
			month: 'Bulan',
			week: 'Minggu',
			months: {
				jan: 'Jan',
				feb: 'Feb',
				mar: 'Mar',
				apr: 'Apr',
				may: 'Mei',
				jun: 'Jun',
				jul: 'Jul',
				aug: 'Agu',
				sep: 'Sep',
				oct: 'Okt',
				nov: 'Nov',
				dec: 'Des'
			},
			days: {
				mon: 'Sen',
				tue: 'Sel',
				wed: 'Rab',
				thu: 'Kam',
				fri: 'Jum',
				sat: 'Sab',
				sun: 'Min'
			},
			open24in7: 'Buka 24 x 7',
			download_app: 'Unduh Terra Charge App',
			phone_no: '9902179575',
			available: 'Tersedia',
			dateandtime: 'Tanggal & Waktu',
			amount: 'Jumlah',
			kwh: 'kWh',
			kw: 'kW',
			hour: 'Jam',
			minute: 'Menit',
			hours: 'Jam',
			mins: 'Menit',
			seconds: 'detik',
			update_available: "Pembaruan aplikasi tersedia",
			faq_more_help: "Butuh bantuan lebih lanjut",
			faq_was_this_helpful: "Apakah konten ini bermanfaat?",
			contact_whatsapp: "Ketik pertanyaan Anda dan hubungi melalui",
			session_since: "Sesi Sejak 1st",
			total_session: "Total Sesi Sejak Tanggal 1",
			type: "Jenis",
			line_channel: "Line",
			whatsapp_channel: "Ada apa",
			transaction_id: "Id Transaksi"
		},
		message: {
			no_internet_wrapper: 'Tidak ada koneksi internet. Harap coba lagi nanti.',
			not_valid_qr_code: 'QR Code Terra tidak valid',
			you_dont_have_vehicles: 'Anda tidak memiliki kendaraan yang disimpan',
			you_dont_have_charging: 'Anda tidak memiliki riwayat pengisian daya',
			loading: 'Memuat...',
			no_charging_records: 'Riwayat pengisian daya tidak ditemukan.',
			nothing_found_in_upcoming: 'Tidak Ada Data di Mendatang',
			nothing_found_in_past: 'Tidak Ada Data di Lampau',
			nothing_found_in_cancelled: 'Tidak Ada Data di Dibatalkan',
			are_you_sure_delete_account: 'Anda yakin ingin menghapus akun Anda?',
			delete_acccount_warning:
				'Dengan menghapus akun Anda, Anda tidak akan bisa lagi mengakses akun Anda, dan semua data Anda akan dijadwalkan untuk penghapusan dalam 7 hari ke depan.',
			error_boundary: 'ErrorBoundary mengalami kegagalan',
			something_went_wrong: 'Ada sesuatu yang salah.',
			stopping: 'Menghentikan...',
			use_this_slider_set_session: 'Geser ini untuk menentukan sesi pengisian daya Anda ke Biaya atau Waktu',
			use_this_slider_time_amount:
				'Geser ini untuk memilih antara waktu atau biaya untuk memulai sesi pengisian daya. Pengisian daya akan otomatis berhenti saat mencapai waktu atau biaya yang dipilih.',
			charger_not_found: 'Pengisi daya tidak ditemukan',
			loging_out: 'Proses logout...',
			otp_cannot_empty: 'OTP tidak boleh kosong',
			resend_otp_in: 'Kirim ulang dalam',
			registration_successful: 'Pendaftaran Berhasil. Harap verifikasi nomor telepon Anda.',
			verify_inputs: 'Harap verifikasi pengisian Anda',
			by_siging_up_agree: 'Dengan mendaftar, Anda menyetujui',
			redirecting: 'Mengarahkan...',
			all_right_reserve: 'Hak Cipta Dilindungi Undang-undang.',
			confirm_change_country: 'Peringatan!!! Mengubah Negara',
			change_country_warning: 'Anda mungkin tidak bisa login dengan OTP bila mengganti negara. Anda mungkin tidak menerima OTP.',
			balance_not_enough: 'Saldo tidak mencukup. Harap Top up.',
			no_charger_found: "Pengisi Daya Tidak Ditemukan",
			unable_to_stop_charging: "Anda tidak diizinkan untuk berhenti mengisi daya sebelum 1 menit. Silakan coba lagi.",
			select_address_to_download_invoice: "Silakan pilih alamat untuk mengunduh faktur",
			no_coupons_found: "Tidak Ada Kupon yang Ditemukan",
			add_to_wallet: "Tambahkan */amount/* ke dompet Anda",
			select_address_to_request_rfid: "Silakan pilih alamat untuk meminta RFID",
			applied: "Terapan",
			coupon_apply_after_session_note: "Catatan :- Kupon hanya akan berlaku ketika sesi pengisian daya berakhir",
			feedback_is_important: "Tanggapan Anda sangat penting bagi Anda",
			no_notification: "Anda tidak memiliki catatan notifikasi apa pun",
			no_ameneties_available: 'Tidak Ada Fasilitas yang Tersedia',
			no_image_available: 'Tidak Ada Gambar Tersedia',
			access_denied: "Akses ke stasiun ini dibatasi",
			faq_not_enabled: "Fitur FAQ Tidak Diaktifkan Untuk Negara Anda.",
		}
	}
};

export default Language;
