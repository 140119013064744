import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem, openDrawer } from 'store/reducers/menuSlice';
// ==============================|| NAVIGATION - LIST ITEM ||============================== //mm

const NavItem = ({ item, level, index, position }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = useSelector(state => state.activeCountry.data)
  const user = useSelector(state => state.user.data)

  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  useEffect(() => {
    if (countries) {
      const found = countries.find((c) => c.short_code === user?.country_short_code);
      setSelectedCountry(found);
    }
  }, [countries, user]);

  let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch(activeItem({ openItem: [id] }));
    if (matchDownMD)
      dispatch(openDrawer({ drawerOpen: false }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const showRFID = item.id === "rfid" && selectedCountry?.is_rfid_enabled;
  const showFAQ = selectedCountry?.is_faq_enabled && item.id === "customerSupport";

  const textColor = 'text.primary';
  const iconSelectedColor = 'primary.main';
  return (
    (showRFID || item.id !== "rfid" ) && (showFAQ || item.id !=="customerSupport") && (
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={() => itemHandler(item.id)}
        style={{
          borderTop: (position === "top" || position === "bottom") && index !== 0 ? '1px solid #EAEBEE' : 'none',
          paddingTop: (position === "top" || position === "bottom") && index !== 0 ? '15px' : '0',
        }}
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 8}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: 'primary.lighter'
            },
            '&.Mui-selected': {
              bgcolor: 'primary.lighter',
              borderRight: `2px solid ${theme.palette.primary.main}`,
              color: 'primary.main',
              '&:hover': {
                color: 'primary.main',
                bgcolor: 'primary.lighter'
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent'
              }
            }
          })
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: 'text.primary',
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'secondary.lighter'
                }
              }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  {item.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ fontSize: '10px', fontWeight: '500' }}>
                  {item.url_desc}
                </Typography>
              </>
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    )
  );

};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
