import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getCurrentLocation, getGeoCountryCode, isLoggedIn as userToken } from 'common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'store/reducers/userSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { openRequestChargerModel } from 'store/reducers/requestChargerSlice';
import { toast } from 'react-toastify';



// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const [isPDPADone, setIsPDPADone] = useState(true)
  const [is_pdpa_page, setPdpaPage] = useState(true)
  const [countryCode, setCountryCode] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(null);
  const user = useSelector(state => state.user.data)
  const theme = useTheme();
  const dispatch = useDispatch()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const navigate = useNavigate()
  const countries = useSelector(state => state.activeCountry.data)
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (countries) {
      const found = countries.find((c) => c.short_code === user?.country_short_code);
      setSelectedCountry(found);
    }
  }, [countries, user]);

  const isLoggedIn = useSelector(state => state.login.data)
  useEffect(() => {
    dispatch(User());
  }, [isLoggedIn]);

  const iconBackColor = theme.palette.navButton.open;
  const iconBackColorOpen = theme.palette.navButton.closed;

  const goBack = () => {
    // window.history.back();
    const matched = document.location.href.match(/\/charger\//)

    if (navigate.length > 1 && !matched) {
      if (location.pathname === "/not-found")
        navigate(-2);
      else
        navigate(-1);
    } else {
      navigate('/home');
    }
  };

  useEffect(() => {
    const setUserCountryCode = async () => {
      if (user?.country) {
        const countryCode = user.country === 'Thailand' ? 'th' : user.country === 'Indonesia' ? 'id' : 'in';
        setCountryCode(countryCode);
      } else {
        const countryCode = (await getGeoCountryCode()) || 'IN';
        setCountryCode(countryCode.toLowerCase());
      }
    };

    setUserCountryCode();
  }, [user]);

  useEffect(() => {
    if (!user?.is_pdpa_done && countryCode == "th") setIsPDPADone(false)
    else {
      setIsPDPADone(true)
    }
  }, [isPDPADone, user, countryCode])

  useEffect(() => {
    if (location.pathname === '/pdpa')
      setPdpaPage(false)
    else
      setPdpaPage(true)
  }, [location])

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await getCurrentLocation();
      setCurrentLocation(location);
    };

    fetchLocation();
  }, []);

  const handleRequestChargerModalToggale = () => {
    if (user && Number(user.request_charger_count) > 5) {
      toast.warning("Limit reached! You can only place 5 charger requests.")
      return false
    }
    navigate("/request-charger")
  }


  // common header
  const mainHeader = (
    userToken() && (
      <Toolbar>
        {is_pdpa_page &&
          location.pathname !== '/home' ? (
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={goBack}
            edge="start"
            sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
          >
            {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </IconButton>
        )
        }
        <HeaderContent />
        {currentLocation && location.pathname == '/home' && selectedCountry?.is_request_charger_enabled && (
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleRequestChargerModalToggale}
            edge="start"
            sx={{ color: 'text.primary', ml: { xs: 0, lg: -1 } }}
          >
            <AddLocationAltIcon />
          </IconButton>
        )}
      </Toolbar>
    )
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
