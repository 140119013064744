import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPlaceNameFromCoordinates } from 'common';

const initialState = {
    data: { placeName: null, location: null },
    isLoading: false,
    error: null,
    apiError: null,
};

export const getPlaceName = createAsyncThunk('user/getPlaceName', async ({ latitude, longitude }) => {
    try {
        const placeName = await getPlaceNameFromCoordinates(latitude, longitude);
        return { placeName: placeName, location: `${latitude},${longitude}` };
    } catch (error) {
        return false;
    }
});

const placeNameByCoordnateSlice = createSlice({
    name: 'getPlaceName',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPlaceName.pending, (state) => {
                state.isLoading = true;
                state.apiError = null;
            })
            .addCase(getPlaceName.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getPlaceName.rejected, (state, action) => {
                state.isLoading = false;
                state.apiError = action.payload.data.message;
            });
    },
})

export default placeNameByCoordnateSlice.reducer