import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const Logout = Loadable(lazy(() => import('pages/authentication/logout/Logout')));

const OnboardingPhone = Loadable(lazy(() => import('pages/onboarding/Phone')));
const OnboardingOTP = Loadable(lazy(() => import('pages/onboarding/Otp')));
const OnboardingName = Loadable(lazy(() => import('pages/onboarding/Name')));
const OnboardingEmail = Loadable(lazy(() => import('pages/onboarding/Email')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
	path: '/',
	element: <MinimalLayout />,
	children: [
		{
			path: 'login',
			element: <AuthLogin />
		},
		{
			path: 'onboarding/phone',
			element: <OnboardingPhone />
		},
		{
			path: 'onboarding/otp',
			element: <OnboardingOTP />
		},
		{
			path: 'onboarding/name',
			element: <OnboardingName />
		},
		{
			path: 'onboarding/email',
			element: <OnboardingEmail />
		},
		{
			path: 'logout',
			element: <Logout />
		},
		{
			path: 'register',
			element: <AuthRegister />
		},
		{
			path: 'forgot-password',
			element: <ForgotPassword />
		}
	]
};

export default LoginRoutes;
