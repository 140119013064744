import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { Outlet } from '../../node_modules/react-router-dom/dist/index';

const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy/PrivacyPolicy')));
const TermsOfService = Loadable(lazy(() => import('pages/terms-of-service/TermsOfService')));
const ContactUs = Loadable(lazy(() => import('pages/contact-us/ContactUs')));

const MobileRoutes = {
    path: '/forapp/',
    element: <div><Outlet /></div>,
    children: [
        {
            path: 'privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: 'terms-of-service',
            element: <TermsOfService />
        },
        {
            path:'contact-us',
            element:<ContactUs />
        }
    ]
}

export default MobileRoutes;