import logo from 'assets/images/icons/menu/blue_logo.png';
import user from 'assets/images/icons/menu/user.svg';
import ac_Charger from 'assets/images/icons/ac-charger-icon.svg';
import scanner from 'assets/images/icons/menu/scanner.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
// ==============================|| LOGO SVG ||============================== //

export const Logo = () => {
	return <img src={logo} alt="Logo" />;
};

export const User = () => {
	return <img src={user} alt="User" />;
};

export const Scanner = () => {
	return <img src={scanner} alt="Scanner" />;
};
export const AcCharger = () => {
	return <img src={ac_Charger} alt="AcCharger" />;
};
export const test = () => {
	return <></>;
};
export default Logo;
