import React, { useEffect, useState } from 'react';
import Login from "pages/onboarding/Phone";
import { setCookie, isLoggedIn as isUserLoggedin, setInLocalStorage } from 'common';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const Authenticator = (props) => {
	const location = useLocation();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const isLoggedIN = useSelector(state => state.login.data)

	const checkIfUserIsLoggedIn = () => {
		const token = isUserLoggedin();
		if (token) {
			setIsLoggedIn(token)
			return token
		} else {
			const currentPath = window.location.pathname;
			setInLocalStorage('login_next', currentPath);
			setIsLoggedIn(false)
			return false
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		if (token !== '' && token !== null & token !== undefined) setCookie('token', token, 30);
	}, [location]);

	useEffect(() => {
		checkIfUserIsLoggedIn()
	}, [isLoggedIN])

	return (
		<>
			{isLoggedIn ? (
				<div style={{ padding: 0 }}>{props.component}</div>
			) : (
				<>
					<Login />
				</>
			)}
		</>
	);

}
export default Authenticator;