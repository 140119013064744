import React from 'react';

const ChangeLanguageIcon = () => {
	return (
		<>
			<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1.83333 11.4583C1.71178 11.4583 1.5952 11.41 1.50924 11.3241C1.42329 11.2381 1.375 11.1216 1.375 11V2.29167C1.37524 2.04863 1.4719 1.81561 1.64375 1.64375C1.81561 1.4719 2.04863 1.37524 2.29167 1.375H10.5417C10.7847 1.37524 11.0177 1.4719 11.1896 1.64375C11.3614 1.81561 11.4581 2.04863 11.4583 2.29167V8.70833C11.4581 8.95137 11.3614 9.18439 11.1896 9.35625C11.0177 9.5281 10.7847 9.62476 10.5417 9.625H3.85642L2.15737 11.324C2.11484 11.3666 2.06433 11.4004 2.00872 11.4234C1.95312 11.4465 1.89352 11.4583 1.83333 11.4583ZM10.5417 2.29075L2.29167 2.29167V9.89358L3.34263 8.84262C3.42856 8.75666 3.54512 8.70836 3.66667 8.70833H10.5417V2.29075ZM20.1667 20.625C20.1065 20.625 20.0469 20.6132 19.9913 20.5901C19.9357 20.5671 19.8852 20.5333 19.8426 20.4907L18.1436 18.7917H11.4583C11.2153 18.7914 10.9823 18.6948 10.8104 18.5229C10.6386 18.3511 10.5419 18.118 10.5417 17.875V11.4583C10.5419 11.2153 10.6386 10.9823 10.8104 10.8104C10.9823 10.6386 11.2153 10.5419 11.4583 10.5417H19.7083C19.9514 10.5419 20.1844 10.6386 20.3562 10.8104C20.5281 10.9823 20.6248 11.2153 20.625 11.4583V20.1667C20.625 20.2882 20.5767 20.4048 20.4908 20.4908C20.4048 20.5767 20.2882 20.625 20.1667 20.625ZM11.4583 11.4583V17.875H18.3333C18.4549 17.875 18.5714 17.9233 18.6574 18.0093L19.7083 19.0602V11.4583H11.4583Z"
					fill="#7D8597"
				/>
				<path
					d="M5.04085 7.79167C4.92495 7.79154 4.81339 7.74751 4.72865 7.66844C4.64391 7.58936 4.59227 7.48112 4.58414 7.3655C4.576 7.24988 4.61198 7.13547 4.68481 7.04531C4.75765 6.95515 4.86194 6.89593 4.97669 6.87958C5.57227 6.79282 6.1242 6.51693 6.55106 6.09263C6.84705 5.79646 7.07221 5.43717 7.20969 5.04167H5.04177C4.92021 5.04167 4.80363 4.99338 4.71768 4.90742C4.63173 4.82147 4.58344 4.70489 4.58344 4.58333C4.58344 4.46178 4.63173 4.3452 4.71768 4.25924C4.80363 4.17329 4.92021 4.125 5.04177 4.125H7.79177C7.85742 4.125 7.9223 4.1391 7.98203 4.16636C8.04176 4.19361 8.09493 4.23337 8.13795 4.28296C8.18098 4.33255 8.21285 4.3908 8.23141 4.45377C8.24996 4.51674 8.25478 4.58296 8.24552 4.64796C8.13246 5.44035 7.7654 6.17469 7.19946 6.74072C6.63352 7.30674 5.89923 7.6739 5.10685 7.78708C5.08514 7.79016 5.06278 7.7917 5.04085 7.79167Z"
					fill="#7D8597"
				/>
				<path
					d="M6.41669 5.04183C6.29513 5.04183 6.17855 4.99354 6.0926 4.90759C6.00665 4.82163 5.95836 4.70505 5.95836 4.5835V3.66683C5.95836 3.54527 6.00665 3.42869 6.0926 3.34274C6.17855 3.25678 6.29513 3.2085 6.41669 3.2085C6.53825 3.2085 6.65483 3.25678 6.74078 3.34274C6.82673 3.42869 6.87502 3.54527 6.87502 3.66683V4.5835C6.87502 4.70505 6.82673 4.82163 6.74078 4.90759C6.65483 4.99354 6.53825 5.04183 6.41669 5.04183ZM8.25048 7.79183C8.23856 7.79183 8.22634 7.79137 8.21381 7.79045C7.68068 7.74918 7.16445 7.58445 6.70595 7.3093C6.24744 7.03415 5.85922 6.65611 5.57198 6.20508C5.50658 6.10261 5.48457 5.97835 5.51079 5.85965C5.537 5.74095 5.6093 5.63752 5.71177 5.57212C5.81425 5.50672 5.9385 5.48471 6.0572 5.51093C6.17591 5.53714 6.27933 5.60944 6.34473 5.71191C6.55556 6.04344 6.84076 6.32127 7.1777 6.52335C7.51463 6.72543 7.89402 6.8462 8.28577 6.87608C8.4053 6.88341 8.51721 6.93723 8.59756 7.02603C8.67791 7.11482 8.72032 7.23154 8.71571 7.3512C8.7111 7.47087 8.65983 7.58397 8.57289 7.66632C8.48594 7.74867 8.37022 7.79372 8.25048 7.79183ZM13.7496 16.9585C13.6715 16.9584 13.5948 16.9383 13.5266 16.9002C13.4585 16.8622 13.4012 16.8073 13.3602 16.7409C13.3192 16.6744 13.2959 16.5986 13.2924 16.5207C13.2889 16.4427 13.3054 16.3651 13.3403 16.2953L15.1736 12.6286C15.2301 12.5238 15.3253 12.4451 15.4388 12.4092C15.5524 12.3733 15.6755 12.383 15.782 12.4362C15.8885 12.4895 15.9701 12.5821 16.0095 12.6945C16.0489 12.8069 16.0431 12.9302 15.9931 13.0384L14.1598 16.705C14.1217 16.7812 14.0633 16.8452 13.9909 16.89C13.9185 16.9347 13.8347 16.9585 13.7496 16.9585Z"
					fill="#7D8597"
				/>
				<path
					d="M17.4171 16.9584C17.332 16.9584 17.2485 16.9347 17.176 16.8899C17.1036 16.8452 17.045 16.7811 17.0069 16.7049L15.1736 13.0382C15.1236 12.9301 15.1178 12.8068 15.1572 12.6944C15.1966 12.582 15.2782 12.4893 15.3847 12.4361C15.4912 12.3828 15.6143 12.3731 15.7279 12.409C15.8414 12.4449 15.9366 12.5237 15.9931 12.6285L17.8264 16.2952C17.8613 16.3649 17.8778 16.4425 17.8743 16.5204C17.8708 16.5984 17.8475 16.6741 17.8066 16.7405C17.7657 16.807 17.7084 16.8618 17.6404 16.8999C17.5723 16.9381 17.4952 16.9582 17.4171 16.9584Z"
					fill="#7D8597"
				/>
				<path
					d="M17.1796 15.5683H13.9869C13.8653 15.5683 13.7487 15.52 13.6628 15.434C13.5768 15.3481 13.5285 15.2315 13.5285 15.1099C13.5285 14.9884 13.5768 14.8718 13.6628 14.7858C13.7487 14.6999 13.8653 14.6516 13.9869 14.6516H17.1796C17.3012 14.6516 17.4178 14.6999 17.5037 14.7858C17.5897 14.8718 17.638 14.9884 17.638 15.1099C17.638 15.2315 17.5897 15.3481 17.5037 15.434C17.4178 15.52 17.3012 15.5683 17.1796 15.5683ZM4.58324 12.3751C4.4926 12.375 4.40401 12.3482 4.32865 12.2978C4.2533 12.2474 4.19457 12.1759 4.15988 12.0921C4.1252 12.0084 4.11612 11.9162 4.1338 11.8273C4.15148 11.7384 4.19511 11.6568 4.25919 11.5927L5.17586 10.676C5.2623 10.5925 5.37808 10.5463 5.49825 10.5474C5.61843 10.5484 5.73338 10.5966 5.81836 10.6816C5.90334 10.7666 5.95154 10.8815 5.95259 11.0017C5.95363 11.1219 5.90743 11.2377 5.82394 11.3241L4.90728 12.2408C4.86477 12.2834 4.81426 12.3172 4.75866 12.3402C4.70305 12.3633 4.64343 12.3751 4.58324 12.3751Z"
					fill="#7D8597"
				/>
				<path
					d="M6.41673 12.3751C6.35654 12.3751 6.29692 12.3633 6.24131 12.3402C6.1857 12.3172 6.13519 12.2834 6.09269 12.2408L5.17602 11.3241C5.09253 11.2377 5.04634 11.1219 5.04738 11.0017C5.04842 10.8815 5.09663 10.7666 5.18161 10.6816C5.26658 10.5966 5.38154 10.5484 5.50171 10.5474C5.62189 10.5463 5.73766 10.5925 5.82411 10.676L6.74077 11.5927C6.80485 11.6568 6.84849 11.7384 6.86617 11.8273C6.88384 11.9162 6.87477 12.0084 6.84008 12.0921C6.8054 12.1759 6.74667 12.2474 6.67132 12.2978C6.59596 12.3482 6.50737 12.375 6.41673 12.3751Z"
					fill="#7D8597"
				/>
				<path
					d="M9.1665 16.0417H5.95817C5.71513 16.0415 5.48211 15.9448 5.31026 15.773C5.1384 15.6011 5.04175 15.3681 5.0415 15.1251V11.0001C5.0415 10.8785 5.08979 10.7619 5.17575 10.676C5.2617 10.59 5.37828 10.5417 5.49984 10.5417C5.62139 10.5417 5.73797 10.59 5.82393 10.676C5.90988 10.7619 5.95817 10.8785 5.95817 11.0001V15.1251H9.1665C9.28806 15.1251 9.40464 15.1734 9.49059 15.2593C9.57655 15.3453 9.62484 15.4618 9.62484 15.5834C9.62484 15.705 9.57655 15.8215 9.49059 15.9075C9.40464 15.9934 9.28806 16.0417 9.1665 16.0417ZM16.4998 9.62506C16.4092 9.62505 16.3206 9.59815 16.2453 9.54779C16.1699 9.49743 16.1112 9.42585 16.0765 9.34211C16.0418 9.25838 16.0327 9.16624 16.0504 9.07734C16.0681 8.98845 16.1117 8.90679 16.1758 8.84269L17.0925 7.92602C17.1789 7.84253 17.2947 7.79634 17.4149 7.79738C17.535 7.79842 17.65 7.84663 17.735 7.93161C17.8199 8.01659 17.8681 8.13154 17.8692 8.25171C17.8702 8.37189 17.824 8.48766 17.7405 8.57411L16.8239 9.49077C16.7814 9.5334 16.7309 9.5672 16.6753 9.59025C16.6196 9.61329 16.56 9.62513 16.4998 9.62506Z"
					fill="#7D8597"
				/>
				<path
					d="M16.5002 9.62507C16.44 9.62513 16.3804 9.6133 16.3248 9.59025C16.2692 9.5672 16.2187 9.5334 16.1762 9.49077L15.2595 8.57411C15.176 8.48766 15.1298 8.37189 15.1309 8.25171C15.1319 8.13154 15.1801 8.01659 15.2651 7.93161C15.3501 7.84663 15.465 7.79842 15.5852 7.79738C15.7054 7.79634 15.8212 7.84253 15.9076 7.92602L16.8243 8.84269C16.8883 8.90679 16.932 8.98845 16.9497 9.07734C16.9673 9.16624 16.9583 9.25838 16.9236 9.34212C16.8889 9.42585 16.8302 9.49743 16.7548 9.54779C16.6795 9.59816 16.5909 9.62505 16.5002 9.62507Z"
					fill="#7D8597"
				/>
				<path
					d="M16.5 9.625C16.3784 9.625 16.2619 9.57671 16.1759 9.49076C16.09 9.4048 16.0417 9.28822 16.0417 9.16667V5.04167H12.8333C12.7118 5.04167 12.5952 4.99338 12.5092 4.90742C12.4233 4.82147 12.375 4.70489 12.375 4.58333C12.375 4.46178 12.4233 4.3452 12.5092 4.25924C12.5952 4.17329 12.7118 4.125 12.8333 4.125H16.0417C16.2847 4.12524 16.5177 4.2219 16.6896 4.39375C16.8614 4.56561 16.9581 4.79863 16.9583 5.04167V9.16667C16.9583 9.28822 16.91 9.4048 16.8241 9.49076C16.7381 9.57671 16.6216 9.625 16.5 9.625Z"
					fill="#7D8597"
				/>
			</svg>
		</>
	);
};

export default ChangeLanguageIcon;
