// project import
// import pages from './pages';
// import dashboard from './dashboard';
// import utilities from './utilities';
// import support from './support';
import myAccount from './myAccount';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [myAccount, dashboard, pages, utilities, support]
  items: [myAccount]
};

export default menuItems;
