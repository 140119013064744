import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toastContainer, getFromLocalStorage, deleteFromLocalStorage } from 'common';
import { toast } from 'react-toastify';
import { Box, Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const MinimalLayout = () => {
	const [popupMessage] = useState(getFromLocalStorage('toast_message'));
	const navigate = useNavigate();

	const handleGoBack = () => {
		if (navigate.length > 1) {
			navigate(-1);
		} else {
			navigate('/home');
		}
	};

	useEffect(() => {
		let toastMessage = JSON.parse(popupMessage);
		if (popupMessage !== undefined && popupMessage !== false) {
			if (toastMessage.type === 'error') toast.error(toastMessage.message);
			if (toastMessage.type === 'success') toast.success(toastMessage.message);
			if (toastMessage.type === 'info') toast.info(toastMessage.message);
			if (toastMessage.type === 'warn') toast.warn(toastMessage.message);
			deleteFromLocalStorage('toast_message');
		}
	}, []);
	return (
		<>
			{toastContainer()}
			<Box
				sx={{
					display: 'flex',
					overflow: 'scroll',
					pb: 0,
					width: '100%'
				}}
			>
				<Grid container>
					<Grid item xs={12} pt={2} pl={1}>
						<KeyboardArrowLeftIcon sx={{ fontSize: 30 }} onClick={handleGoBack} />
					</Grid>
					<Grid item xs={12}>
						<Outlet />
					</Grid>
				</Grid>

				{/* <Header open={open} handleDrawerToggle={handleDrawerToggle} />
				<Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> */}
				{/* <Box
					component="main"
					sx={{
						width: '100%',
						flexGrow: 1
					}}
					id="mybo"
				>
				</Box> */}
			</Box>
		</>
	);
};

export default MinimalLayout;
