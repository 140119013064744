import React, { useEffect, useState } from 'react';
import { Grid, Modal, Box, Typography, Fab, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isBrowser, downloadApp } from 'common';
import { Language } from 'language';
import {
    useLocation,
} from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

const DownloadApp = () => {
    const [open, setOpen] = useState(null);
    const handleClose = () => setOpen(false);
    const isRunningInBrowser = isBrowser();
    const location = useLocation();
    const { pathname } = location;

    const getTodaysDate = () => {
        return new Date().getTime();
    };    

    useEffect(() => {
        const fetchVariableFromLocalStorage = async () => {
            const popup_hidden_till = await new Promise((resolve) => {
                resolve(localStorage.getItem('download_popup'));
            });
    
            if (!pathname.includes('wallet')) {
                const currentTime = new Date().getTime();
                if (popup_hidden_till) {
                    const storedTime = parseInt(popup_hidden_till, 10);
                    if (currentTime - storedTime < 3600000) {
                        setOpen(false);
                    } else {
                        setOpen(true);
                    }
                } else {
                    setOpen(true);
                }
            }
        };
    
        fetchVariableFromLocalStorage();
    }, []);

    return (
        <>
            {open && isRunningInBrowser && (
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="Close"
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                            elevation={3}
                            onClick={() => {
                                window.localStorage.setItem('download_popup', getTodaysDate());
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </Fab>
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h2"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            paddingBottom={5}
                        >
                            {Language.generic.download_app}
                        </Typography>
                        <Grid container padding={2} rowSpacing={4.5} columnSpacing={2.75}>
                            {downloadApp(50)}
                            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    aria-label="Close"
                                    elevation={3}
                                    onClick={() => {
                                        window.localStorage.setItem('download_popup', getTodaysDate());
                                        handleClose();
                                    }}
                                >
                                    {Language.button.continue_on_app}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )}
        </>
    )
}

export default DownloadApp