import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
// import Logo from 'components/Logo';
import { Logo } from 'components/icons';
import { Language } from 'language/index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userIcon from "../../../../assets/user/profile.png"
import { useNavigate } from "react-router-dom";


// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, handleDrawerToggle }) => {
	const user = useSelector(state => state.user.data)
	const theme = useTheme();
	const navigate = useNavigate();

	const [firstNameInitial, setFirstNameInitial] = useState('')
	const [lastNameInitial, setLastNameInitial] = useState('')

	useEffect(() => {
		if (user) {
			const fullName = user.name;
			const nameParts = fullName?.split(' ');
			setFirstNameInitial(nameParts?.[0] ? nameParts[0][0] : '')
			setLastNameInitial(nameParts?.[1] ? nameParts[1][0] : '')
		}
	}, [user]);

	const handleProfileClick = () => {
		navigate("/my-account")
		handleDrawerToggle()
	}

	return (
		<DrawerHeaderStyled open={open}>
			{user.name && (user.name !== '' || user.name !== null) && (
				<Grid container onClick={handleProfileClick} alignItems="center">
					<Grid item xs={3} display="flex" justifyContent="right" alignItems="center">
						{
							user.picture ? (
								<div style={{
									width: 60,
									height: 60,
									borderRadius: '50%',
									overflow: 'hidden',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
									<img
										alt={firstNameInitial + lastNameInitial}
										src={user.picture}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover'
										}}
									/>
								</div>

							) : (
								<img alt={firstNameInitial + lastNameInitial} src={userIcon} style={{ borderRadius: 50, height: 60, width: 60 }} />
							)
						}
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={8} display="flex" justifyContent="left" alignItems="center">
						<Grid container direction="column">
							<Grid item justifyContent="left" alignItems="bottom">
								<span style={{ fontSize: 18, fontWeight: 'bold' }}>{user.name}</span>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</DrawerHeaderStyled>
	);
};

DrawerHeader.propTypes = {
	open: PropTypes.bool
};

export default DrawerHeader;
