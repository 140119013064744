import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery
} from '@mui/material';

// project import

// assets


// sx styles


// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);



  return (
    <></>
    // <Box sx={{ flexShrink: 0, ml: 0.75 }}>
    //   <IconButton
    //     disableRipple
    //     color="secondary"
    //     sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
    //     aria-label="open profile"
    //     ref={anchorRef}
    //     aria-controls={open ? 'profile-grow' : undefined}
    //     aria-haspopup="true"
    //     onClick={handleToggle}
    //   >
    //     <Badge badgeContent={4} color="primary">
    //       <BellOutlined />
    //     </Badge>
    //   </IconButton>
    //   <Popper
    //     placement={matchesXs ? 'bottom' : 'bottom-end'}
    //     open={open}
    //     anchorEl={anchorRef.current}
    //     role={undefined}
    //     transition
    //     disablePortal
    //     popperOptions={{
    //       modifiers: [
    //         {
    //           name: 'offset',
    //           options: {
    //             offset: [matchesXs ? -5 : 0, 9]
    //           }
    //         }
    //       ]
    //     }}
    //   >
    //     {({ TransitionProps }) => (
    //       <Transitions type="fade" in={open} {...TransitionProps}>
    //         <Paper
    //           sx={{
    //             boxShadow: theme.customShadows.z1,
    //             width: '100%',
    //             minWidth: 285,
    //             maxWidth: 420,
    //             [theme.breakpoints.down('md')]: {
    //               maxWidth: 285
    //             }
    //           }}
    //         >
    //           <ClickAwayListener onClickAway={handleClose}>
    //             <MainCard
    //               title="Notification"
    //               elevation={0}
    //               border={false}
    //               content={false}
    //               secondary={
    //                 <IconButton size="small" onClick={handleToggle}>
    //                   <CloseOutlined />
    //                 </IconButton>
    //               }
    //             >
    //               <List
    //                 component="nav"
    //                 sx={{
    //                   p: 0,
    //                   '& .MuiListItemButton-root': {
    //                     py: 0.5,
    //                     '& .MuiAvatar-root': avatarSX,
    //                     '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
    //                   }
    //                 }}
    //               >
    //                 <ListItemButton>
    //                   <ListItemAvatar>
    //                     <Avatar
    //                       sx={{
    //                         color: 'success.main',
    //                         bgcolor: 'success.lighter'
    //                       }}
    //                     >
    //                       <GiftOutlined />
    //                     </Avatar>
    //                   </ListItemAvatar>
    //                   <ListItemText
    //                     primary={
    //                       <Typography variant="h6">
    //                         You{' '}
    //                         <Typography component="span" variant="subtitle1">
    //                           Have a coupon code
    //                         </Typography>{' '}
    //                         <b>TC782HJJW</b> to add ₹ 100 to your wallet.
    //                       </Typography>
    //                     }
    //                     secondary="2 min ago"
    //                   />
    //                   <ListItemSecondaryAction>
    //                     <Typography variant="caption" noWrap>
    //                       3:00 AM
    //                     </Typography>
    //                   </ListItemSecondaryAction>
    //                 </ListItemButton>
    //                 <Divider />
    //                 <ListItemButton>
    //                   <ListItemAvatar>
    //                     <Avatar
    //                       sx={{
    //                         color: 'primary.main',
    //                         bgcolor: 'primary.lighter'
    //                       }}
    //                     >
    //                       <MessageOutlined />
    //                     </Avatar>
    //                   </ListItemAvatar>
    //                   <ListItemText
    //                     primary={
    //                       <Typography variant="h6">
    //                         <Typography component="span" variant="subtitle1">
    //                           Your Charging session is completed
    //                         </Typography>{' '}
    //                         Consumed Unit: 3 kW, Amount: ₹ 300.
    //                       </Typography>
    //                     }
    //                     secondary="5 August"
    //                   />
    //                   <ListItemSecondaryAction>
    //                     <Typography variant="caption" noWrap>
    //                       6:00 PM
    //                     </Typography>
    //                   </ListItemSecondaryAction>
    //                 </ListItemButton>
    //                 <Divider />
    //                 <ListItemButton>
    //                   <ListItemAvatar>
    //                     <Avatar
    //                       sx={{
    //                         color: 'error.main',
    //                         bgcolor: 'error.lighter'
    //                       }}
    //                     >
    //                       <SettingOutlined />
    //                     </Avatar>
    //                   </ListItemAvatar>
    //                   <ListItemText
    //                     primary={
    //                       <Typography variant="h6">
    //                         Your Vehicle battery is at &nbsp;
    //                         <Typography component="span" variant="subtitle1">
    //                           100%
    //                         </Typography>{' '}
    //                       </Typography>
    //                     }
    //                     secondary="7 hours ago"
    //                   />
    //                   <ListItemSecondaryAction>
    //                     <Typography variant="caption" noWrap>
    //                       2:45 PM
    //                     </Typography>
    //                   </ListItemSecondaryAction>
    //                 </ListItemButton>
    //                 <Divider />
    //                 <ListItemButton>
    //                   <ListItemAvatar>
    //                     <Avatar
    //                       sx={{
    //                         color: 'primary.main',
    //                         bgcolor: 'primary.lighter'
    //                       }}
    //                     >
    //                       C
    //                     </Avatar>
    //                   </ListItemAvatar>
    //                   <ListItemText
    //                     primary={
    //                       <Typography variant="h6">
    //                         <Typography component="span" variant="subtitle1">
    //                           New Vehicle added to your list.
    //                         </Typography>{' '}
    //                         VIN:{' '}
    //                         <Typography component="span" variant="subtitle1">
    //                           123849.
    //                         </Typography>
    //                       </Typography>
    //                     }
    //                     secondary="Daily scrum meeting time"
    //                   />
    //                   <ListItemSecondaryAction>
    //                     <Typography variant="caption" noWrap>
    //                       9:10 PM
    //                     </Typography>
    //                   </ListItemSecondaryAction>
    //                 </ListItemButton>
    //                 <Divider />
    //                 <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
    //                   <ListItemText
    //                     primary={
    //                       <Typography variant="h6" color="primary">
    //                         View All
    //                       </Typography>
    //                     }
    //                   />
    //                 </ListItemButton>
    //               </List>
    //             </MainCard>
    //           </ClickAwayListener>
    //         </Paper>
    //       </Transitions>
    //     )}
    //   </Popper>
    // </Box>
  );
};

export default Notification;
