import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Button,
  Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import urls from 'backend_urls';
import axios from 'axios';
import { authHeaders, deleteFromLocalStorage, setInLocalStorage } from 'common';



const consentOptions = [
  {
    title: "1. Consent for Product Development and Ease of Use",
    description: "We may collect your information to analyze and improve our services. This will allow us to facilitate ease of use and provide a fast and satisfying user experience. You agree that Terra Charge may collect, use, and disclose your personal information for such purposes."
  },
  {
    title: "2. Consent to Offer Promotions of Business Partners Products",
    description: "In order to inform you about promotions, discounts, special privileges, or services from our business partners, you agree that Terra Charge may use and disclose your personal information for such purposes."
  },
  {
    title: "3. Consent to Offer Personalized Products",
    description: "In order to offer you personalized promotions, content, products, or services, and to improve and personalize our products, you agree that Terra Charge may collect, use, and disclose your personal information for such purposes."
  }
];

const UserConsent = () => {
  const [consentStatus, setConsentStatus] = useState(Array(consentOptions.length).fill(null));
  const [agreeToAll, setAgreeToAll] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const navigate = useNavigate();
  deleteFromLocalStorage('pdpa')

  useEffect(() => {
    const anyAgreeChecked = consentStatus.every(status => status === 'agree');
    const allConsentsChecked = consentStatus.every(status => status !== null);
    setIsNextEnabled(anyAgreeChecked && allConsentsChecked);
  }, [consentStatus]);

  const handleConsentChange = (index, value) => {
    const newConsentStatus = [...consentStatus];
    newConsentStatus[index] = value;
    setConsentStatus(newConsentStatus);
    setAgreeToAll(newConsentStatus.every(status => status === 'agree'));
  };

  const handleAgreeToAllChange = (event) => {
    const isChecked = event.target.checked;
    setAgreeToAll(isChecked);
    setConsentStatus(Array(consentOptions.length).fill(isChecked ? 'agree' : null));
  };

  const handlePDPA = async () => {
    try {
      await axios
        .get(`${urls.updateUserDetails}is_pdpa_done/true/`, { headers: authHeaders() })
        .then(() => {
          setInLocalStorage('pdpa',true)
          navigate('/home')
          location.reload();
        })
        .catch(() => {
          setGlobalToastMessage('error', 'can not change country.');
        });
    } catch (error) {
      // Handle error
      console.error('Error fetching data:', error);
    }
  }
  return (
    <>
      {!nextPage ? (
        <>
          <div style={{ marginBottom: "30px" }}>
            {consentOptions.map((option, index) => (
              <Grid container key={index} rowSpacing={4.5} columnSpacing={2.75} padding={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom>
                    {option.title}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {option.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <FormControl style={{ justifyContent: "space-between", display: "flex", marginLeft: "10px" }}>
                    <RadioGroup row aria-labelledby={`option-${index}-radio-buttons-group-label`} name={`option-${index}-radio-buttons-group`} style={{ justifyContent: "space-between", display: "flex" }} value={consentStatus[index]} onChange={(event) => handleConsentChange(index, event.target.value)}>
                      <FormControlLabel value="agree" control={<Radio />} label="Agree" style={{ border: "1px solid #E8EBEF", borderRadius: "8px", padding: "0px 10px" }} />
                      <FormControlLabel value="disagree" control={<Radio />} label="Disagree" style={{ border: "1px solid #E8EBEF", borderRadius: "8px", padding: "0px 10px" }} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </div>
          <Grid container rowSpacing={4.5} columnSpacing={2.75} padding={2} style={{ position: "fixed", bottom: "0px", zIndex: "9999", backgroundColor: "#fff", borderTop: "1px solid #eeeeee" }}>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={agreeToAll} onChange={handleAgreeToAllChange} />} label="Agree to all" />
              </FormGroup>
              <Button variant="contained" style={{ width: "90%", borderRadius: "25px", padding: "15px 0px" }} disabled={!isNextEnabled} onClick={() => setNextPage(true)}>Next</Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div style={{ marginBottom: "30px" }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} padding={2}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  I have read and understood the details of the consent text above. As specified in the company privacy policy, details can be found at
                </Typography>
                <Link href=" https://app.terracharge.net/privacy-policy " variant="body2">
                  {' https://app.terracharge.net/privacy-policy '}
                </Link>
                <Typography variant="body2" gutterBottom>
                  or additional details in the future.
                </Typography>
                <Typography variant="h6" gutterBottom>Note: Companies that disclose information include
                </Typography>
                <ol>
                  <li>
                    <Typography variant="body2" gutterBottom>
                      Business groups within Terra Charge (Thailand)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" gutterBottom>
                      Business partners and other legal entities outside the business group. The list may increase or decrease in the future. In case of an increase, the company will notify you of the increase in writing or by electronic mail (email) or text message (SMS) or other channels as the company deems appropriate. The company will also inform you of your rights and channels to refuse to send personal data to the recipients on the increased list. If you do not refuse within 30 (thirty) days from the date of receiving notification from the company, it shall be deemed that you agree to disclose your personal data to the recipients on the increased list.
                    </Typography>
                  </li>
                </ol>
              </Grid>
            </Grid>
          </div>
          <Grid container rowSpacing={4.5} columnSpacing={2.75} padding={2} style={{ position: "fixed", bottom: "0px", zIndex: "9999", backgroundColor: "#fff", borderTop: "1px solid #eeeeee" }}>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Button variant="contained" style={{ width: "90%", borderRadius: "25px", padding: "15px 0px" }} onClick={handlePDPA}>Confirm</Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default UserConsent;
