// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import NoInternetConnection from 'NoInternetWrapper';
import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from "./graphql/client";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFromLocalStorage } from 'common';
import { activeCountry } from 'store/reducers/activeCountrySlice';
import TokenHandler from 'TokenHandler';
import { APIProvider } from '@vis.gl/react-google-maps';

const App = () => {
	const userData = useSelector(state => state.user.data)
	const navigate = useNavigate()
	const isLoggedIN = useSelector(state => state.login.data)
	const dispatch = useDispatch()
	const location = useLocation();
	const handleSelectedLanguage = (event) => {
		localStorage.setItem('selectedLanguage', event.detail.lang);
		window.location.reload()
	};

	useEffect(() => {
		dispatch(activeCountry())
	}, [dispatch])

	useEffect(() => {
		document.addEventListener('updatedLanguage', handleSelectedLanguage);
		return () => {
			document.removeEventListener('updatedLanguage', handleSelectedLanguage);
		};
	}, []);

	const checkIfUserPdpaDone = () => {
		let pdpa = getFromLocalStorage('pdpa')
		if ((!userData.is_pdpa_done && userData.country === "Thailand" && !pdpa))
			navigate('/pdpa')
		else if ((userData.is_pdpa_done && pdpa && userData.country === "Thailand" && location.pathname === '/pdpa')) {
			navigate('/home')
		}
	}

	useEffect(() => {
		if (isLoggedIN)
			checkIfUserPdpaDone();
	}, [location.pathname, userData])


	return (
		<ApolloProvider client={client}>
			<NoInternetConnection>
				<ThemeCustomization>
					<APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
						<ScrollTop>
							<TokenHandler />
							<Routes />
						</ScrollTop>
					</APIProvider>
				</ThemeCustomization>
			</NoInternetConnection>
		</ApolloProvider>
	);
};

export default App;
