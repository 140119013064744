import React, { useEffect } from 'react';

const TokenHandler = () => {
  useEffect(() => {
    // Function to get query parameter value by name
    const getQueryParam = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Function to set a cookie
    const setCookie = (name, value, days) => {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/`;
    };

    // Function to remove a token from the URL
    const cleanUrl = () => {
      const url = new URL(window.location.href);
      url.searchParams.delete('token');
      window.history.replaceState({}, document.title, url.toString());
    };

    // Check if token exists in the URL
    const token = getQueryParam('token');
    if (token) {
      // Set token in localStorage
      localStorage.setItem('token', token);

      // Set token in cookies
      setCookie('token', token, 7); // Expires in 7 days, adjust as needed

      // Optionally, you can remove the token from the URL to clean it up
      cleanUrl();
    }
  }, []);

  return null; // This component does not render anything
};

export default TokenHandler;